import * as React from "react";
import Layout from "../components/layout";

import { StaticImage } from "gatsby-plugin-image";

import Helmet from "react-helmet";

export default function Index() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Details</title>
        <meta name="description" content="A long description of what makes your map unique."/>
        <meta name="image" content="https://awmcphee.ca/alberta.jpg" />
        <meta property="og:image" content="https://awmcphee.ca/alberta.jpg"/>
        <meta name="twitter:card" content="https://awmcphee.ca/alberta.jpg"/>
      </Helmet>
      <h1>Details</h1>
      <h2>Design Features</h2>
      <h2>Index</h2>
      <h3>A1</h3>
      <div className="ind_item"><div><StaticImage
          src="../ab/onefour.jpg"
          alt="A small excerpt of McPhee's Alberta map."
        /></div>
      <h4>Onefour Research Ranch</h4>
      <p>Once an Agriculture Canada research station, Onefour is now co-managed by the University of Alberta. This remote site is one of the driest places in Canada.</p></div>
      <div className="ind_item"><div><StaticImage
          src="../ab/rat_zone.jpg"
          alt="A small excerpt of McPhee's Alberta map."
        /></div>
      <h4>Rat Control Zone</h4>
      <p>Every Albertan knows that Alberta is rat-free. Few outside the RCZ are aware that provincial exterminators are licensed to burn any abandoned building found within 10 miles of the province's eastern border.</p></div>
      <div className="ind_item"><div><StaticImage
          src="../ab/cypress.jpg"
          alt="A small excerpt of McPhee's Alberta map."
        /></div>
      <h4>Cypress Hills</h4>
      <p>Canada's only interprovincial park, the lushly forested Cypress Hills are a unique island ecosystem, rising 700 meters above the bald southeastern prairie.</p></div>
      <h3>A2</h3>
      <div className="ind_item"><div><StaticImage
          src="../ab/etzikom.jpg"
          alt="A small excerpt of McPhee's Alberta map."
        /></div>
      <h4>Etzikom Windmill Centre</h4>
      <p>Many small-town museums suffer from a lack of ambition. Not the Windmill Centre, which occupies an entire school. Don't worry about picking a good day to visit the outdoor exhibit, Etzikom is always windy.</p></div>
      <div className="ind_item"><div><StaticImage
          src="../ab/nemiskam.jpg"
          alt="A small excerpt of McPhee's Alberta map."
        /></div>
      <h4>Nemiskam National Park</h4>
      <p>Three national parks of Canada were once set aside for the benefit of the pronghorn antelope. Pronghorn numbers soon rebounded, and the parks are now long gone.</p></div>
      <div className="ind_item"><div><StaticImage
          src="../ab/mcbean.jpg"
          alt="A small excerpt of McPhee's Alberta map."
        /></div>
      <h4>Pinto McBean</h4>
      <p>Very few Canadian roadside statues carry firearms, but Pinto is packing heat. Free beans are available at the Bow Island visitor centre.</p></div>
      <h3>A3</h3>
      <div className="ind_item"><div><StaticImage
          src="../ab/new_york.jpg"
          alt="A small excerpt of McPhee's Alberta map."
        /></div>
      <h4>New York Hutterite Colony</h4>
      <p>Visiting here is nothing like the real thing; the Hutterites are much friendlier. The colony is likely named after the Anabaptist settlement of New York, Ukraine.</p></div>
      <div className="ind_item"><div><StaticImage
          src="../ab/milk_river.jpg"
          alt="A small excerpt of McPhee's Alberta map."
        /></div>
      <h4>Eight Flags</h4>
      <p>By historical technicality, the town of Milk River was once part of Louisiana.</p></div>
      <div className="ind_item"><div><StaticImage
          src="../ab/lethbridge.jpg"
          alt="A small excerpt of McPhee's Alberta map."
        /></div>
      <h4>Birds of Prey Centre</h4>
      <p>There are a lot of things to do around Lethbridge, but this is the only area attraction guaranteeing that all visitors can hold a real bird of prey.</p></div>
      <h3>A4</h3>
      <div className="ind_item"><div><StaticImage
          src="../ab/cardston.jpg"
          alt="A small excerpt of McPhee's Alberta map."
        /></div>
      <h4>Cardston Temple</h4>
      <p>Alberta's first modernist building looms over the predominantly-Mormon town of Cardston.</p></div>
      <div className="ind_item"><div><StaticImage
          src="../ab/wind.jpg"
          alt="A small excerpt of McPhee's Alberta map."
        /></div>
      <h4>Wind Turbines</h4>
      <p>At Pincher Creek, you're passing through the windiest place in the country. Each propellor on the map represents an entire installation of turbines.</p></div>
      <div className="ind_item"><div><StaticImage
          src="../ab/blood.jpg"
          alt="A small excerpt of McPhee's Alberta map."
        /></div>
      <h4>Communities of the Blood Reserve</h4>
      <p>Canadian maps have a problem with Indian reserves. They're usually depicted as mysterious blobs of federal land, rather than places where people live. Not even Google correctly marks the existence of Levern and Moses Lake.</p></div>
      <h3>A5</h3>
      <div className="ind_item"><div><StaticImage
          src="../ab/crowsnest.jpg"
          alt="A small excerpt of McPhee's Alberta map."
        /></div>
      <h4>Crowsnest Pass</h4>
      <p>The mining communities of the Pass have slowly grown into a single municipality, incorporated by special charter. I show its boundaries accurately.</p></div>
      <h3>B1</h3>
      <div className="ind_item"><div><StaticImage
          src="../ab/suffield.jpg"
          alt="A small excerpt of McPhee's Alberta map."
        /></div>
      <h4>CFB Suffield</h4>
      <p>A bombing range established for the British RAF in WWII. Many an airman got his start practicing in the clear skies near Medicine Hat. Look closely at the base's road network, which forms a set of enormous concentric circles.</p></div>
      <div className="ind_item"><div><StaticImage
          src="../ab/wawaskesy.jpg"
          alt="A small excerpt of McPhee's Alberta map."
        /></div>
      <h4>Wawaskesy National Park</h4>
      <p>Alberta's other pronghorn national park, expropriated and joined onto CFB Suffield.</p></div>
      <h3>B2</h3>
      <div className="ind_item"><div><StaticImage
          src="../ab/brooks.jpg"
          alt="A small excerpt of McPhee's Alberta map."
        /></div>
      <h4>Brooks Aqueduct</h4>
      <p>In an incredible feat of engineering, arid Newell County was once served by an aqueduct with a below-grade rail crossing.</p></div>
      <div className="ind_item"><div><StaticImage
          src="../ab/dinosaur.jpg"
          alt="A small excerpt of McPhee's Alberta map."
        /></div>
      <h4>Dinosaur Provincial Park</h4>
      <p>World-famous badland scenery. Fossils are still being excavated from the park today.</p></div>
    </Layout>
  );
}
